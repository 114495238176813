/* global SightMap */

SPACES.SightMapFactory = (function () {
  function SightMapFactory () {
    var factory = this
    this.embed = new SightMap.Embed('spaces_sightmap')

    this.embed.on('ready', function () {
      factory.initSightMap()
    })
  }

  SightMapFactory.prototype.initSightMap = function () {
    const factory = this

    if (SPACES.sightmapFilter) {
      SPACES.utility.log('SPACES: Initializing SightMap with:', SPACES.sightmapFilter)
      if (SPACES.sightmapFilter.length > 0) {
        factory.embed.setUnitIdMatches(SPACES.sightmapFilter, { filterEditing: false })
        factory.embed.disableUI(['filters'])
      }
    } else {
      SPACES.utility.log('SPACES: Failed to initialize SM due to empty filter')
    }
  }

  SightMapFactory.prototype.setUnitIdMatches = function (_idArray = SPACES.sightmapFilter) {
    const factory = this

    this.embed.on('ready', function () {
      if (_idArray.length > 0) {
        factory.embed.setUnitIdMatches(_idArray, { filterEditing: false })
        factory.embed.disableUI(['filters'])
      }
    })
  }

  SightMapFactory.prototype.locateUnitById = function (_unitID) {
    const factory = this

    this.embed.on('ready', function () {
      factory.embed.locateUnitById(_unitID, { filterEditing: false })
      factory.embed.disableUI(['filters'])
    })
  }

  return SightMapFactory
})()
