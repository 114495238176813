// Comment about functionality

SPACES.UnitSightMapTrigger = (function ($) {
  function UnitSightMapTrigger () {
    this.$controlElement = $('[data-spaces-control="unit-sightmap-trigger"]')
  }

  UnitSightMapTrigger.prototype.init = function () {
    const control = this

    control.$controlElement.each(function () {
      $smTrigger = $(this)
      
      $smTrigger.click(function (e) {
        e.preventDefault()

        SPACES.locateUnitId = $(this).data('spaces-id')
        SPACES.utility.log(`recieved locate on map click event for unit: ${SPACES.locateUnitId} unit-sightmap-trigger.js:18`)

        if (SPACES.sightmapLoaded) {
          // We already have a SigtMap embed loaded in the placeeholder open it
          SPACES.utility.log('SightMap is already loaded opening existing instance unit-sightmap-trigger.js:22')
          SPACES.spacesModal.open('[data-spaces-control="placeholder"]')
          SPACES.sightmapFactory.embed.setUnitIdMatches([SPACES.locateUnitId])
          SPACES.sightmapFactory.embed.locateUnitById(SPACES.locateUnitId)
        } else {
          // Load the map tab from ajax append it to the placeholder and then
          // open the embed in the modal
          SPACES.utility.log('SightMap not already loaded, loading from ajax unit-sightmap-trigger.js:29')
          SPACES.ajaxFactory.getSightMap({ from: 'unit' }) // calls back to openSightMapFromAjax
        }
      })
    })
  }

  return UnitSightMapTrigger
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.unitSightMapTrigger = new SPACES.UnitSightMapTrigger()
  SPACES.unitSightMapTrigger.init()
})

document.addEventListener('spaces_tab', function (e) {
  // Run init after AJAX tab switch
  SPACES.unitSightMapTrigger = new SPACES.UnitSightMapTrigger()
  SPACES.unitSightMapTrigger.init()
})
