window.SPACES || (window.SPACES = {})

SPACES.UtilityFactory = (function () {
  function UtilityFactory () {}

  UtilityFactory.prototype.formatCalendarDate = function (_date) {
    const pattern = /(\d{4})-(\d{2})-(\d{2})/
    if (!_date || !_date.match(pattern)) {
      return null
    }
    return _date.replace(pattern, '$2/$3/$1')
  }

  UtilityFactory.prototype.camelCase = function (_str, _pluralize = false) {
    return _str.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase() })
  }

  UtilityFactory.prototype.log = function (_banner, _obj = false) {
    if (SPACES.spacesConfig.spaces_mode_debug) {
      console.log(_banner)

      if (_obj) {
        console.log(_obj)
      }
    }
  }

  UtilityFactory.prototype.addDays = function (_date, _days) {
    const result = new Date(_date)
    result.setDate(result.getDate() + _days)
    return result
  }

  UtilityFactory.prototype.getMinPrice = function () {
    // Get an array of the values
    const termArr = Object.values(SPACES.currentLeaseRequest.terms)
    return termArr.reduce((min, p) => p.price < min ? p.price : min, termArr[0].price)
  }

  UtilityFactory.prototype.getMinTerm = function (_unitJSON) {
    if (_unitJSON.lease_terms.length < 1) {
      SPACES.utility.log('SPACES: No lease terms for unit:', _unitJSON)
      return 0
    }
    const term = _unitJSON.lease_terms.reduce(function (prev, curr) {
      return prev.price < curr.price ? prev : curr
    })
    return term.lease_term
  }

  UtilityFactory.prototype.getUnitJSON = function (_unitId) {
    const unitArr = SPACES.spacesUnitJSON.filter(obj => { return obj.id === String(_unitId) })
    return unitArr[0]
  }

  UtilityFactory.prototype.debounce = function (_func) {
    let timer
    return function (event) {
      if (timer) { clearTimeout(timer) }
      timer = setTimeout(_func, 100, event)
    }
  }

  UtilityFactory.prototype.isDate = function (_inputString) {
    if(_inputString.toString() == parseInt(_inputString).toString()) return false;
    const tryDate = new Date(_inputString);
    return (tryDate && tryDate.toString() != "NaN" && tryDate != "Invalid Date");
  }

  return UtilityFactory
})()
