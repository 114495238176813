SPACES.MetricsFactory = (function ($) {
  function MetricsFactory () { }

  MetricsFactory.prototype.tabImpressionEvent = function (_elementData) {
    const factory = this
    let eventCode = ''
    switch (SPACES.currentTab) {
      case 'unit':
        eventCode = 'metrics.tabs.unit.impression'
        this.sendEvent(eventCode, { units: factory.buildUnitData(_elementData) })
        break
      case 'plan':
        eventCode = 'metrics.tabs.plan.impression'
        this.sendEvent(eventCode, { test: true })
        break
      case 'map':
        eventCode = 'metrics.tabs.map.impression'
        this.sendEvent(eventCode, { test: true })
        break
      case 'community':
        eventCode = 'metrics.tabs.community.impression'
        this.sendEvent(eventCode, { test: true })
        break
      default:
        console.error('SPACES: Unknown tab for metrics event')
    }

    this.sendEvent('metrics.tab.impression', { tab: SPACES.currentTab, numItemsDisplayed: SPACES.statusCount })
  }

  MetricsFactory.prototype.sendEvent = function (_eventCode, _data = {}) {
    if (_eventCode) {
      SPACES.emit(_eventCode, { name: _eventCode, data: _data })
    } else {
      const error = 'SPACES: Error dispatchEvent called with invalid eventCode'
      SPACES.utility.log(error)
      console.error(error)
    }
  }

  MetricsFactory.prototype.buildUnitData = function (_unitElementData) {
    if (_unitElementData) {
      const $elements = []
      const units = []

      _unitElementData.forEach(function (item) {
        $elements.push($(item.element))
      })

      $elements.forEach(function ($elem) {
        const unit = {
          id: $elem.data('spaces-unit-id'),
          unitNumber: $elem.data('spaces-unit').toString(),
          area: $elem.data('spaces-sort-area'),
          areaDisplay: `${Number($elem.data('spaces-sort-area')).toLocaleString('en-US')} Sq. Ft.`,
          price: $elem.data('spaces-sort-price'), // nullable
          priceDisplay: `$${Number($elem.data('spaces-sort-price')).toLocaleString('en-US')}`,
          availableOn: $elem.data('spaces-soonest'), // nullable
          availableOnDisplay: $elem.find('[data-spaces-control="unit-available-on"]').text().replace(/(\r\n|\n|\r)/gm, '').replace(/\s+/g, ' ').trim(),
          floorPlan: {
            id: $elem.data('spaces-plan-id'),
            name: $elem.data('spaces-sort-plan-name'),
            bedroomCount: $elem.data('spaces-sort-bed'),
            bathroomCount: $elem.data('spaces-sort-bed')
          }
        }

        units.push(unit)
      })

      return units
    }

    return { error: 'No data found' }
  }

  return MetricsFactory
})(jQuery)
