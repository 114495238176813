SPACES.ControlFactory = (function ($) {
  function ControlFactory () {
    this.collectControls()
  }

  ControlFactory.prototype.collectControls = function () {
    const factory = this

    SPACES.$controls = {}
    this.$controlElements = $('[data-spaces-control]')

    this.$controlElements.each(function () {
      const $control = $(this)
      const controlName = $control.data('spaces-control')
      const controlNameCC = SPACES.utility.camelCase(controlName)

      if (typeof SPACES.$controls[controlNameCC] !== 'undefined') {
        SPACES.$controls[controlNameCC].push($control)
      } else {
        SPACES.$controls[controlNameCC] = $control
      }
    })

    factory.bindControls()
  }

  ControlFactory.prototype.bindControls = function () {
    const factory = this

    // factory.bindControlMobileFiltersTrigger()
    let widest = 0

    switch (SPACES.currentTab) {
      case 'plan-detail':
        // factory.hideStatusControl()
        // factory.hideFilters()
        // factory.hideTabControls()
        // factory.hideControls()
        factory.bindAccordions()
        // factory.bindControlUnitSightmapTrigger()
        factory.initDetailControls()
        factory.initLeaseTermControls()
        factory.bindApplyLinks()
        // factory.bindLeaseTermApplyLinks()
        // factory.bindReturToSearch()
        // factory.bindShareLinks()
        factory.initSpecialsTips()
        factory.bindPlanViewSightmapLinks()
        $('.spaces__label-unit-number').each(function () { widest = Math.max(widest, $(this).outerWidth()) }).css({ width: widest })
        widest = 0
        $('.spaces__label-available-on').each(function () { widest = Math.max(widest, $(this).outerWidth()) }).css({ width: widest })
        break
      case 'unit-detail':
        // factory.hideStatusControl()
        // factory.hideControls()
        factory.bindAccordions()
        // factory.bindControlUnitSightmapTrigger()
        factory.initDetailControls()
        factory.initLeaseTermControls()
        factory.bindApplyLinks()
        // factory.bindLeaseTermApplyLinks()
        // factory.bindReturToSearch()
        // factory.bindShareLinks()
        factory.initSpecialsTips()
        // factory.bindApplyLinks()
        break
      case 'unit':
        // factory.showControls()
        // factory.showFilters()
        // factory.showTabControls()
        // factory.bindControlUnitSightmapTrigger()
        factory.initLeaseTermControls()
        // factory.bindControlSort()
        factory.updateStatusCountObj(SPACES.spacesConfig.spaces_label_unit_label + 's') // FIXME
        // factory.showStatusControl()
        factory.bindApplyLinks()
        factory.initSpecialsTips()
        // factory.bindLeaseTermApplyLinks()
        break
      case 'plan':
        // factory.showControls()
        // factory.showFilters()
        // factory.showTabControls()
        // factory.bindControlPlanDetailLinks()
        // factory.bindControlSort()
        // factory.showStatusControl()
        factory.bindPlanViewSightmapLinks()
        factory.updateStatusCountObj('Floor Plans')
        break
      case 'map':
        // factory.showControls()
        // factory.showFilters()
        // factory.showTabControls()
        // factory.hideStatusControl()
        factory.updateStatusCountObj(SPACES.spacesConfig.spaces_label_unit_label + 's') // FIXME
        if (SPACES.$tab.data('isotope')) {
          SPACES.$tab.isotope('destroy')
        }
        break
      case 'community':
        // factory.showControls()
        // factory.showFilters()
        // factory.bindControlSort()
        // factory.showStatusControl()
        factory.initCommunityMap()
        factory.updateStatusCountObj('Communities')
        break
      case 'apply':
        // factory.hideFilters()
        // factory.hideTabControls()
        // factory.hideControls()
        // factory.hideStatusControl()
        // factory.bindReturToSearch()
    }
  }

  ControlFactory.prototype.showControls = function () {
    $('[data-spaces-control="control"]').show()
  }

  ControlFactory.prototype.hideControls = function () {
    $('[data-spaces-control="control"]').hide()
  }

  ControlFactory.prototype.bindAccordions = function () {
    SPACES.$controls.accordion.each(function () {
      const $control = $(this)
      $control.accordion({
        onOpen: function () {
          $(this).prev('.title').attr('aria-expanded', 'false')
          const $trigger = $(this).prev('.title').find('[data-spaces-control="accordion-trigger"]')
          $trigger.html($trigger.html().replace('Expand', 'Collapse').replace('down', 'up'))
        },
        onClose: function () {
          $(this).prev('.title').attr('aria-expanded', 'true')
          const $trigger = $(this).prev('.title').find('[data-spaces-control="accordion-trigger"]')
          $trigger.html($trigger.html().replace('Collapse', 'Expand').replace('up', 'down'))
        }
      })
    })
  }

  ControlFactory.prototype.bindControlUnitSightmapTrigger = function () {
    if (typeof (SPACES.$controls.unitSightmapTrigger) === 'undefined') {
      return
    }

    SPACES.$controls.unitSightmapTrigger.each(function () {
      const $control = $(this)

      $control.click(function (e) {
        e.preventDefault()

        SPACES.locateUnitId = $(this).data('spaces-id')

        SPACES.utility.log(`recieved locate on map click event for unit: ${SPACES.locateUnitId} control.js:132`)

        if (SPACES.sightmapLoaded) {
          // We already have a SigtMap embed loaded in the placeeholder open it
          SPACES.utility.log('SightMap is already loaded opening existing instance control.js:136')
          SPACES.spacesModal.open('[data-spaces-control="placeholder"]')
          SPACES.sightmapFactory.embed.setUnitIdMatches([SPACES.locateUnitId])
          SPACES.sightmapFactory.embed.locateUnitById(SPACES.locateUnitId)
        } else {
          // Load the map tab from ajax append it to the placeholder and then
          // open the embed in the modal
          SPACES.utility.log('SightMap not already loaded, loading control.js:141')
          SPACES.ajaxFactory.getSightMap({ from: 'unit' }) // calls back to openSightMapFromAjax
        }
      })
    })
  }

  ControlFactory.prototype.openSightMapFromAjax = function (_opts) {
    SPACES.utility.log(`Received callback from Ajax from ${_opts.from} control.js:156`)
    for (let i = 0; i < _opts.data.length; i++) {
      const $elem = $(_opts.data[i])
      $('[data-spaces-control="placeholder-content"]').append($elem)
    }
    SPACES.sightmapFactory = new SPACES.SightMapFactory()
    SPACES.sightmapLoaded = true

    SPACES.spacesModal.open('[data-spaces-control="placeholder"]')
    SPACES.sightmapFactory.embed.on('ready', function () {
      switch (_opts.from) {
        case 'unit':
          SPACES.sightmapFactory.embed.setUnitIdMatches([SPACES.locateUnitId])
          SPACES.sightmapFactory.embed.locateUnitById(SPACES.locateUnitId)
          break;
        case 'community':
          // nothing just open the sightmap
          break;
        default:
          SPACES.sightmapFactory.embed.setUnitIdMatches(SPACES.locateUnitIds)
          break;
      }
    })
  }

  ControlFactory.prototype.locateUnitOnMap = function () {
    SPACES.utility.log('SPACES: Opening single unit on SightMap')
    SPACES.sightmapFactory = new SPACES.SightMapFactory()
    SPACES.sightmapFactory.embed.locateUnitById(SPACES.locateUnitId)
  }

  ControlFactory.prototype.locateUnitsOnMap = function () {
    SPACES.utility.log('SPACES: Highlighting multiple units SightMap')
    SPACES.sightmapFactory = new SPACES.SightMapFactory()
    SPACES.sightmapFactory.embed.setUnitIdMatches(SPACES.locateUnitIds)
  }

  ControlFactory.prototype.bindPlanViewSightmapLinks = function () {
    $('[data-spaces-control="plan-sightmap-trigger"]').each(function () {
      const $control = $(this)

      $control.click(function (e) {
        e.preventDefault()

        let ids = $(this).data('spaces-sightmap-filter')
        ids = String(ids).split(',')

        SPACES.locateUnitIds = ids

        SPACES.utility.log('SPACES: requested unit IDs:', SPACES.locateUnitIds)

        if (!SPACES.sightmapLoaded) {
          SPACES.ajaxFactory.getSightMap({ from: 'plan' })
        } else {
          SPACES.spacesModal.open('[data-spaces-control="placeholder"]')
          SPACES.sightmapFactory.embed.setUnitIdMatches(SPACES.locateUnitIds)
        }
      })
    })
  }

  ControlFactory.prototype.initDetailControls = function () {
    if (typeof SPACES.$controls.detailListView !== 'undefined') {
      SPACES.$controls.detailListView.click(function (e) {
        e.preventDefault()

        SPACES.$controls.detailListView.addClass('spaces__active')
        SPACES.$controls.detailMapView.removeClass('spaces__active')

        SPACES.$controls.detailMap.hide()
        SPACES.$controls.detailList.show()
      })
    }

    if (SPACES.spacesConfig.sightmap_url) {
      // Setup SightMap
      SPACES.sightmapFactory = new SPACES.SightMapFactory()

      let ids = $('[data-spaces-sightmap-filter]').data('spaces-sightmap-filter')
      ids = String(ids).split(',')

      if (typeof SPACES.$controls.detailMapView !== 'undefined') {
        SPACES.$controls.detailMapView.click(function (e) {
          e.preventDefault()

          SPACES.$controls.detailMapView.addClass('spaces__active')
          SPACES.$controls.detailListView.removeClass('spaces__active')

          SPACES.$controls.detailList.hide()
          SPACES.$controls.detailMap.show({
            complete: function () {
              SPACES.sightmapFactory.embed.setUnitIdMatches(ids) // not sure why we need to go directly to the embed here
            }
          })
        })
      }
    }
  }

  // ControlFactory.prototype.bindReturToSearch = function () {
  //   SPACES.$controls.returnToSearch.unbind()
  //   SPACES.$controls.returnToSearch.click(function (e) {
  //     e.preventDefault()
  //
  //     SPACES.utility.log('SPACES: last query', SPACES.lastQuery)
  //
  //     let lastTab = 'plan'
  //
  //     if (typeof SPACES.lastQuery === 'undefined') {
  //       // The user landed on a link and did not navigate through SPACES to here
  //
  //       // Get the current URL
  //       const newUrl = new URL(window.location.href.replace(/#k=.*/gi, ''))
  //
  //       // Remove apply and detail specific URL keys
  //       newUrl.search = newUrl.search.replace(/&spaces_tab=.[^&]*/, '').replace(/&MoveInDate=.[^&]*/, '').replace(/&LeaseTerm=.[^&]*/, '').replace(/&Unitid=.[^&]*/, '').replace(/&detail=.[^&]*/, '')
  //       window.history.pushState({ path: newUrl.href }, '', newUrl.href)
  //
  //       SPACES.tabFactory.switchTab(lastTab)
  //       if (!SPACES.filterFactory) {
  //         SPACES.filterFactory = new SPACES.FilterFactory()
  //       }
  //       SPACES.controlFactory.showFilters()
  //       SPACES.controlFactory.showStatusControl()
  //
  //       return // load the iinitial tab
  //     }
  //
  //     // The user navigated through SPACES to a page with a return link
  //     const lastTabArr = SPACES.lastQuery.match(/spaces_tab=(.[^&]*)/)
  //
  //     if (lastTabArr) {
  //       lastTab = lastTabArr[1]
  //     }
  //
  //     const newUrl = new URL(window.location.href.replace(/#k=.*/gi, ''))
  //
  //     newUrl.search = SPACES.lastQuery.replace(/&MoveInDate=.[^&]*/, '').replace(/&LeaseTerm=.[^&]*/, '').replace(/&Unitid=.[^&]*/, '')
  //
  //     if (lastTab === 'plan-detail') {
  //       // The user was on the plan detail tab last, load the detail page again
  //       SPACES.ajaxFactory.getDetail()
  //       return
  //     }
  //
  //     // If we made it here, the user came from the unit tab
  //     newUrl.search = newUrl.search.replace(/&detail=.[^&]*/, '')
  //
  //     window.history.pushState({ path: newUrl.href }, '', newUrl.href)
  //
  //     SPACES.tabFactory.switchTab(lastTab)
  //   })
  // }

  ControlFactory.prototype.initLeaseTermControls = function () {
    if (typeof (SPACES.$controls.leaseTermOpener) === 'undefined') {
      return
    }

    SPACES.$controls.leaseTermOpener.each(function () {
      const $control = $(this)
      const $parent = $control.parents('[data-spaces-unit]')

      // const thisUnitId = $control.data('spaces-unit-id')
      // const unitJSON = SPACES.utility.getUnitJSON(thisUnitId)
      // const minTerm = SPACES.utility.getMinTerm(unitJSON)

      // $control.text(`${minTerm} Month`)

      $control.click(function (e) {
        SPACES.utility.log('Opening lease term selector')
        e.preventDefault()

        const $opener = $(this)
        $opener.addClass('open')
        $parent.addClass('spaces__focused')

        const soonest = $opener.data('spaces-soonest')

        SPACES.currentLeaseRequest = []
        SPACES.currentLeaseRequest.unitId = $opener.data('spaces-unit-id')

        const initialPrice = $opener.data('spaces-unit-price')

        SPACES.currentLeaseRequest.applyUrl = {
          initial: $opener.data('spaces-unit-apply-url'),
          current: $opener.data('spaces-unit-apply-url')
        }

        SPACES.utility.log('SPACES: Current apply URL:', SPACES.currentLeaseRequest.applyUrl.current)

        // Set the intial apply url and price label
        // SPACES.$controls.leaseTermApply.attr('href', SPACES.currentLeaseRequest.applyUrl.initial + '&LeaseTerm=' + minTerm)
        SPACES.$controls.leaseTermPrice.text('$' + initialPrice + ' /month')

        // Set the dates for the calendar, this needs to be fixed to have a max days out
        const formattedForCalendar = SPACES.utility.formatCalendarDate(soonest)

        const soonestDate = new Date(formattedForCalendar)
        let startDate = new Date()
        const maxDaysOut = parseInt(SPACES.spacesConfig.spaces_option_max_days_out)
        const endDate = SPACES.utility.addDays(startDate, maxDaysOut)

        if (soonestDate > startDate) {
          startDate = soonestDate
        }

        const calendarStartDate = SPACES.utility.formatCalendarDate(startDate.toJSON().slice(0, 10))
        const calendarEndDate = SPACES.utility.formatCalendarDate(endDate.toJSON().slice(0, 10))

        // bind the date picker control
        SPACES.controlFactory.bindControlLeaseTermCalendar(calendarStartDate, calendarEndDate)

        // load the lease terms into the select control
        const unitArr = SPACES.spacesUnitJSON.filter(obj => { return obj.id === String(SPACES.currentLeaseRequest.unitId) })
        SPACES.currentLeaseRequest.terms = unitArr[0].lease_terms

        SPACES.controlFactory.buildLeaseTermOptions()
        SPACES.controlFactory.updateLeaseTermSelector($parent)

        // SPACES.spacesBoxFactory.openLeaseTermSelector()
        SPACES.spacesModal.open('[data-spaces-modal="spaces-lease-modal"]')
      })
    })
  }

  ControlFactory.prototype.buildLeaseTermOptions = function () {
    SPACES.$controls.leaseTermSelector.empty()
    const minPrice = SPACES.utility.getMinPrice()
    const terms = SPACES.currentLeaseRequest.terms

    SPACES.utility.log('Min price for current lease terms:', minPrice)

    Object.keys(SPACES.currentLeaseRequest.terms).forEach(function (key) {
      let option
      option = `<option data-price="${terms[key].price}" value="${terms[key].lease_term}">${terms[key].lease_term} Month</option>`

      if (terms[key].price === minPrice) {
        option = `<option data-price="${terms[key].price}" value="${terms[key].lease_term}" selected>${terms[key].lease_term} Month</option>`
      }

      SPACES.$controls.leaseTermSelector.prepend(option)
    })

    SPACES.$controls.leaseTermSelector
  }

  ControlFactory.prototype.updateLeaseTermSelector = function (_unitElem) {
    SPACES.$controls.leaseTermSelector.change(function () {
      const $this = $(this)
      const $parent = $(_unitElem)
      const $parentOpener = $parent.find('[data-spaces-control="lease-term-opener"]')
      const $parentPrice = $parent.find('[data-spaces-control="unit-price-label"]')
      const $parentApply = $parent.find('[data-spaces-control="apply"]')
      const $selectedOption = $this.find(':selected')
      const price = $selectedOption.data('price')
      const term = $selectedOption.val()

      SPACES.$controls.leaseTermPrice.text('$' + parseInt(price).toLocaleString() + ' / month')
      $parentPrice.text('$' + parseInt(price).toLocaleString())
      $parentOpener.text('$' + parseInt(price).toLocaleString() + '/mo')

      if (SPACES.spacesConfig.apply_prodiver === 'realpage' || SPACES.spacesConfig.apply_prodiver === 'rentcafe') {
        if (SPACES.spacesConfig.apply_prodiver === 'realpage') {
          if (SPACES.currentLeaseRequest.applyUrl.current.includes('LeaseTerm')) {
            SPACES.currentLeaseRequest.applyUrl.current = SPACES.currentLeaseRequest.applyUrl.current.replace(/LeaseTerm=.*$/i, 'LeaseTerm=' + term)
          } else {
            SPACES.currentLeaseRequest.applyUrl.current = `${SPACES.currentLeaseRequest.applyUrl.current}&LeaseTerm=${term}`
          }
        } else {
          if (SPACES.currentLeaseRequest.applyUrl.current.includes('sLeaseTerm')) {
            SPACES.currentLeaseRequest.applyUrl.current = SPACES.currentLeaseRequest.applyUrl.current.replace(/sLeaseTerm=.*$/i, 'sLeaseTerm=' + term)
          } else {
            SPACES.currentLeaseRequest.applyUrl.current = `${SPACES.currentLeaseRequest.applyUrl.current}&sLeaseTerm=${term}`
          }
        }
      }

      if (SPACES.spacesConfig.apply_prodiver === 'entrada') {
        if (SPACES.currentLeaseRequest.applyUrl.current.includes('term_month')) {
          SPACES.currentLeaseRequest.applyUrl.current = SPACES.currentLeaseRequest.applyUrl.current.replace(/term_month=.*$/i, 'term_month=' + term)
        } else {
          SPACES.currentLeaseRequest.applyUrl.current = `${SPACES.currentLeaseRequest.applyUrl.current}&term_month=${term}`
        }
      }

      // SPACES.$controls.leaseTermApply.attr('href', SPACES.currentLeaseRequest.applyUrl.current)
      $parentApply.attr('href', SPACES.currentLeaseRequest.applyUrl.current)
    })
  }

  ControlFactory.prototype.bindControlLeaseTermCalendar = function (_calendarStartDate, _calendarEndDate) {
    // let pickedDate = _calendarStartDate
    //
    // if (SPACES.pickedDate && SPACES.pickedDate !== '') {
    //   // We have a previously picked date
    //   if (Date.parse(SPACES.pickedDate) > Date.parse(_calendarStartDate)) {
    //     // only set the picked date if this unit is available on that date, otherwise set it to the
    //     // soonest available date
    //     pickedDate = SPACES.pickedDate
    //   }
    // }

    SPACES.utility.log('SELECTED DATE:', SPACES.currentLeaseRequest.date)

    SPACES.$controls.leaseTermDateSelection.datepicker({
      autoHide: true,
      startDate: _calendarStartDate,
      endDate: _calendarEndDate,
      container: '#spaces-lease-term-date-container',
      inline: true
    }).on('pick.datepicker', function (e) {
      SPACES.currentLeaseRequest.date = e.date.toJSON().slice(0, 10)
      SPACES.pickedDate = e.date
      SPACES.ajaxFactory.getLeaseTermsForUnit()

      SPACES.utility.log('SPACES: Current apply URL', SPACES.currentLeaseRequest.applyUrl.current)

      if (SPACES.spacesConfig.apply_prodiver === 'realpage' || SPACES.spacesConfig.apply_prodiver === 'rentcafe') {
        if (SPACES.currentLeaseRequest.applyUrl.current.includes('MoveInDate')) {
          const newHref = SPACES.currentLeaseRequest.applyUrl.current.replace(/(MoveInDate=)(.{10})/, `$1${SPACES.currentLeaseRequest.date}`)
          SPACES.currentLeaseRequest.applyUrl.current = newHref
        } else {
          SPACES.currentLeaseRequest.applyUrl.current = `${SPACES.currentLeaseRequest.applyUrl.current}&MoveInDate=${SPACES.currentLeaseRequest.date}`
        }
      }

      if (SPACES.spacesConfig.apply_prodiver === 'entrada') {
        if (SPACES.currentLeaseRequest.applyUrl.current.includes('lease_start_date')) {
          const newHref = SPACES.currentLeaseRequest.applyUrl.current.replace(/(lease_start_date=)(.{10})/, `$1${SPACES.currentLeaseRequest.date}`)
          SPACES.currentLeaseRequest.applyUrl.current = newHref
        } else {
          SPACES.currentLeaseRequest.applyUrl.current = `${SPACES.currentLeaseRequest.applyUrl.current}&lease_start_date=${SPACES.currentLeaseRequest.date}`
        }
      }
    }).datepicker()
  }

  ControlFactory.prototype.bindControlPlanDetailLinks = function () {
    SPACES.utility.log('SPACES:', 'Bind plan detail links')
    SPACES.$controls.planDetailLink.each(function () {
      const $control = $(this)

      $control.click(function (e) {
        e.preventDefault()
        SPACES.lastQuery = window.location.search
        SPACES.currentDetailId = $control.data('spaces-detail-id')
        SPACES.queryFactory.changeUrl('detail', SPACES.currentDetailId)
        SPACES.ajaxFactory.getDetail()
      })
    })
  }

  ControlFactory.prototype.bindApplyLinks = function () {
    if (typeof (SPACES.$controls.apply) === 'undefined') {
      return
    }

    if (SPACES.spacesConfig.apply_prodiver !== 'realpage') {
      return
    }

    SPACES.$controls.apply.each(function () {
      const $control = $(this)
      const thisUnitId = $control.data('spaces-unit-id')
      const unitJSON = SPACES.utility.getUnitJSON(thisUnitId)
      const minTerm = SPACES.utility.getMinTerm(unitJSON)

      // Set the lease term to the min term
      $control.attr('href', $control.attr('href') + '&LeaseTerm=' + minTerm)

      // DEPRECATED: These now open in a new tab/window
      // $control.click(function (e) {
      //   e.preventDefault()
      //
      //   SPACES.lastQuery = window.location.search
      //
      //   const thisHref = $control.attr('href')
      //   const linkArr = thisHref.split('?')
      //   const query = '?' + linkArr[1]
      //
      //   SPACES.queryFactory.changeUrl('spaces_tab', 'apply' + query.replace('?spaces_tab=apply', ''))
      //   SPACES.ajaxFactory.getApply()
      // })
    })
  }

  ControlFactory.prototype.bindLeaseTermApplyLinks = function () {
    SPACES.$controls.leaseTermApply.each(function () {
      const $control = $(this)

      $control.click(function (e) {
        e.preventDefault()

        SPACES.lastQuery = window.location.search

        const thisHref = $control.attr('href')
        const linkArr = thisHref.split('?')
        const query = '?' + linkArr[1]

        SPACES.queryFactory.changeUrl('spaces_tab', 'apply' + query.replace('?spaces_tab=apply', ''))

        SPACES.ajaxFactory.getApply()

        SPACES.ajaxFactory.getApply()
        $.spacesbox.close()
      })
    })
  }

  ControlFactory.prototype.bindShareLinks = function () {
    const currentUrl = encodeURIComponent(window.location.href)

    const shareSubject = `I think you'll like this floor plan at ${SPACES.spacesConfig.spaces_asset_name}`

    const emailLink = `mailto:?subject=${shareSubject}&body=${currentUrl}`

    const smsLink = `sms:&body=${shareSubject}\n\n${currentUrl}`

    SPACES.$controls.shareEmail.attr('href', emailLink)
    SPACES.$controls.shareSms.attr('href', smsLink)
  }

  ControlFactory.prototype.showNoResults = function () {
    SPACES.utility.log('SPACES: SHOWING NO RESULTS')
    SPACES.$controls.noResultsMessage.slideDown()
    // SPACES.$controls.statusCount.hide()
    // SPACES.$controls.statusSort.hide()
    // SPACES.isoFactory.filterFeatured()
  }

  ControlFactory.prototype.hideNoResults = function () {
    SPACES.$controls.noResultsMessage.slideUp()
    SPACES.$controls.statusCount.show()
    // SPACES.$controls.statusSort.show()
  }

  // ControlFactory.prototype.updateResultsDisplay = function () {
  //   if (SPACES.statusCount < 1) {
  //     SPACES.controlFactory.showNoResults()
  //   } else {
  //     SPACES.controlFactory.hideNoResults()
  //   }
  //
  //   SPACES.$controls.statusCountNumber.text(SPACES.statusCount)
  // }

  ControlFactory.prototype.hideStatusControl = function () {
    SPACES.$controls.status.slideUp()
  }

  ControlFactory.prototype.showStatusControl = function () {
    SPACES.$controls.status.slideDown()
  }

  ControlFactory.prototype.hideFilters = function () {
    SPACES.$controls.filters.hide()
    if (SPACES.$controls.returnToSearch) {
      SPACES.$controls.returnToSearch.show()
    }
  }

  ControlFactory.prototype.showFilters = function () {
    SPACES.$controls.filters.show()
    if (SPACES.$controls.returnToSearch) {
      SPACES.$controls.returnToSearch.hide()
    }
  }

  ControlFactory.prototype.hideTabControls = function () {
    $('[data-spaces-control="tabs"]').hide()
  }

  ControlFactory.prototype.showTabControls = function () {
    $('[data-spaces-control="tabs"]').show()
  }

  ControlFactory.prototype.planDetailMapView = function () {
    let ids = $('[data-spaces-sightmap-filter]').data('spaces-sightmap-filter')
    ids = String(ids).split(',')

    SPACES.$controls.detailMapView.addClass('spaces__active')
    SPACES.$controls.detailListView.removeClass('spaces__active')

    SPACES.$controls.detailList.hide()
    SPACES.$controls.detailMap.show({
      complete: function () {
        SPACES.sightmapFactory.embed.setUnitIdMatches(ids) // not sure why we need to go directly to the embed here

        $('#spaces_sightmap')[0].scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        })
      }
    })
  }

  ControlFactory.prototype.planDetailUnitListMapView = function (_id) {
    SPACES.$controls.detailMapView.addClass('spaces__active')
    SPACES.$controls.detailListView.removeClass('spaces__active')

    SPACES.$controls.detailList.hide()
    SPACES.$controls.detailMap.show({
      complete: function () {
        SPACES.sightmapFactory.embed.locateUnitById(_id) // not sure why we need to go directly to the embed here

        $('#spaces_sightmap')[0].scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        })
      }
    })
  }

  ControlFactory.prototype.initSpecialsTips = function () {
    if (!SPACES.isDevice) {
      return false
    }

    const $tipSpecial = $('[data-spaces-control="tip-specials"]')

    $tipSpecial.each(function () {
      const $tip = $(this)
      const content = $tip.data('spaces-tooltip')
      $tip.click(function () {
        $.spacesbox.open(`<div class="message">${content}</div>`)
      })
    })
  }

  ControlFactory.prototype.initCommunityMap = function () {
    const factory = this

    // The google map script has not been added to the page for some reason
    // bail out of initializing the map
    if (typeof google === 'undefined') {
      return false
    }

    // The element where the Google map would be injected is not present for
    // some reason, bail.
    if (typeof SPACES.$controls.map === 'undefined') {
      return false
    }

    if (SPACES.communityJSON === false) {
      return false
    }

    const mapArgs = {
      scrollwheel: false,
      maxZoom: 20,
      minZoom: 4,
      disableDefaultUI: true,
      zoomControl: true,
      streetViewControl: true,
      gestureHandling: 'cooperative'
    }

    if (typeof googleMapsStyler !== 'undefined') {
      mapArgs.styles = googleMapsStyler
    }

    SPACES.communityMap = new google.maps.Map(SPACES.$controls.map.get(0), mapArgs)
    factory.addCommunityMapMarkers()
  }

  // FIXME: move this to a specific MapFactory
  ControlFactory.prototype.addCommunityMapMarkers = function (_args = { filtered: false, highlightID: 0 }) {
    if (typeof google === 'undefined') {
      return false
    }

    const factory = this
    SPACES.utility.log('SPACES: Adding community map markers control.js:749', _args)
    SPACES.bounds = new google.maps.LatLngBounds()

    let jsonData = SPACES.communityJSON

    if (_args.filtered) {
      jsonData = SPACES.filteredCommunityJSON
    }

    for (let i = 0; i < jsonData.length; i++) {
      const markerItem = {}
      const propertyLatLng = new google.maps.LatLng(jsonData[i].location.lat, jsonData[i].location.lng)
      markerItem.title = jsonData[i].name
      markerItem.propertyLatLng = propertyLatLng
      markerItem.map = SPACES.communityMap
      markerItem.property = jsonData[i].id
      markerItem.icon = SPACES.spacesConfig.community_marker_path

      if (_args.highlightID && _args.highlightID === markerItem.property) {
        markerItem.icon = SPACES.spacesConfig.community_marker_path_active
      }

      const marker = new google.maps.Marker({
        property: markerItem.property,
        position: markerItem.propertyLatLng,
        title: markerItem.title,
        icon: markerItem.icon,
        map: markerItem.map
      })

      const $communityCard = $(`[data-community-id="${markerItem.property}"]`).clone(true, true)
      $communityCard.removeAttr('style')
      $communityCard.addClass('spaces-infowindowed')
      const infoWindowContentString = $communityCard.prop('outerHTML')

      marker.infoWindow = new google.maps.InfoWindow({ content: infoWindowContentString })

      if (_args.highlightID && _args.highlightID === marker.property) {
        marker.infoWindow.open({
          anchor: marker,
          map: marker.map,
          shouldFocus: true
        })
      }

      if (_args.highlightID) {
        if (_args.highlightID === marker.property) {
          SPACES.bounds.extend(marker.position)
        }
      } else {
        SPACES.bounds.extend(marker.position)
      }

      SPACES.markers.push(marker)
      factory.bindMarker(marker)
    }

    SPACES.communityMap.fitBounds(SPACES.bounds)
  }

  ControlFactory.prototype.bindMarker = function (_marker) {
    google.maps.event.addListener(_marker, 'click', function () {
      for (let i = 0; i < SPACES.markers.length; i++) {
        if (SPACES.markers[i].infoWindow) {
          SPACES.markers[i].infoWindow.close()
          SPACES.markers[i].setIcon(SPACES.spacesConfig.community_marker_path)
        }
      }

      _marker.infoWindow.open({
        anchor: _marker,
        map: _marker.map,
        shouldFocus: true
      })

      $('[data-spaces-control="community-card"]').each(function () {
        $(this).removeClass('spaces-active')
      })

      $(`[data-community-id="${_marker.property}"]`).addClass('spaces-active')

      _marker.setIcon(SPACES.spacesConfig.community_marker_path_active)
    })

    google.maps.event.addListener(_marker.infoWindow, 'domready', function () {
      $('.spaces-infowindowed [data-spaces-control="community-unit-trigger"]').click(function () {
        const communityID = $(this).data('community-id')
        const communitySlug = $(this).data('community-slug')
        SPACES.communityUnitTrigger.getUnitModal(communityID, communitySlug)
      })

      $('.spaces-infowindowed [data-spaces-control="community-sightmap-trigger"]').click(function () {
        $('[data-spaces-control="community-sightmap-content"]').empty()
        const assetID = $(this).data('spaces-asset-id')
        if (assetID) {
          SPACES.communitySightMapTrigger.getSightMap(assetID)
        }
      })
    })
  }

  ControlFactory.prototype.clearCommunityMapMarkers = function () {
    for (var m = 0; m < SPACES.markers.length; m++) {
      SPACES.markers[m].setMap(null)
    }
    SPACES.markers = []
  }

  ControlFactory.prototype.updateAssetFilter = function (_args = { filtered: false }) {
    let communityJSON = SPACES.spacesCommunityJSON

    if (_args.filtered) {
      communityJSON = SPACES.filteredCommunityJSON
    }

    $('[data-spaces-filter="asset"]').empty()

    const allOption = '<option value="*">All</option>'
    $('[data-spaces-filter="asset"]').append(allOption)

    communityJSON.forEach(function (_community) {
      const option = `<option value=".${_community.community_filter_class}">${_community.name}</option>`
      $('[data-spaces-filter="asset"]').append(option)
    })

    $('[data-spaces-filter="asset"]').spacesSelect('update')
  }

  ControlFactory.prototype.updateStatusCount = function () {
    $('[data-spaces-control="status-count-number"]').each(function () {
      const $elem = $(this)
      $elem.text(SPACES.statusCount)
    })
  }

  ControlFactory.prototype.updateStatusCountObj = function (_obj) {
    $('[data-spaces-control="status-count-obj"]').each(function () {
      $(this).text(_obj)
    })
  }

  return ControlFactory
})(jQuery)
