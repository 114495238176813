// Manages functionality for return to search, currently just taking the user
// back a single page from detail and apply views.

SPACES.ReturnToSearchControl = (function ($) {
  function ReturnToSearchControl () {
    this.controlElement = $('[data-spaces-control="return-to-search"]')
  }

  ReturnToSearchControl.prototype.init = function () {
    this.controlElement.click(function (ev) {
      ev.preventDefault()
      history.back()
    })
  }

  return ReturnToSearchControl
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.returnToSearchControl = new SPACES.ReturnToSearchControl()
  SPACES.returnToSearchControl.init()
})

document.addEventListener('spaces_tab', function (e) {
  // Run init after AJAX tab switch
  SPACES.returnToSearchControl = new SPACES.ReturnToSearchControl()
  SPACES.returnToSearchControl.init()
})
