SPACES.TabFactory = (function ($) {
  function TabFactory () {
    // Highlight initial tab
    const initialTab = SPACES.spacesConfig.tab

    $(`[data-spaces-tab-control="${initialTab}"]`).addClass('spaces-active')

    SPACES.$tab = $('[data-spaces-control="tab"]')
    SPACES.currentTab = SPACES.$tab.data('spaces-tab')
    SPACES.$tabContainer = $('[data-spaces-control="tab-container"]')
    SPACES.$tabControls = $('[data-spaces-tab-control]')

    this.initTabsControls()
  }

  TabFactory.prototype.initTabsControls = function () {
    SPACES.$tabControls.each(function () {
      const $tabControl = $(this)

      $tabControl.click(function () {
        SPACES.tabFactory.resetTabs()
        $(this).addClass('spaces-active')
        $('[data-spaces-control="interstitial"]').addClass('spaces-active')

        const requestedTab = $tabControl.data('spaces-tab-control')
        $tabControl.find('[data-spaces-control="spinner"]').show()
        SPACES.tabFactory.switchTab(requestedTab)
      })
    })
  }

  TabFactory.prototype.resetTabs = function () {
    SPACES.$tabControls.each(function () {
      $(this).removeClass('spaces-active')
    })
  }

  TabFactory.prototype.switchTab = function (_requestedTab) {
    const requestedTab = _requestedTab
    const requestedAssetID = SPACES.spacesConfig.spaces_asset_id

    $.post(SPACES.spacesConfig.spaces_ajax_url, {
      action: 'spaces_switch_tab',
      requested_tab: requestedTab,
      requested_asset_id: requestedAssetID,
      inventory_mode: SPACES.spacesConfig.inventory_mode,
      spaces_market_id: SPACES.spacesConfig.spaces_market_id
    }).done(function (data) {
      SPACES.tabFactory.initTab(data, requestedTab)
    })
  }

  TabFactory.prototype.initTab = function (_data, _requestedTab, _overrideElem = false) {
    SPACES.utility.log('SPACES: INIT TAB')
    const data = JSON.parse(_data)

    let $incomingTab

    for (let i = 0; i < data.length; i++) {
      const $elem = $(data[i])
      $incomingTab = $elem
      if (_overrideElem) {
        $(_overrideElem).prepend($elem)
      } else {
        $elem.css('display', 'none')
        SPACES.$tabContainer.prepend($elem)
      }
    }

    if (_overrideElem === false) {
      SPACES.$tab.slideUp()
      SPACES.$tab.remove()
      SPACES.$tab = $incomingTab

      SPACES.$tab.slideDown({
        complete: function () {
          SPACES.currentTab = _requestedTab

          if (_requestedTab !== 'plan-detail' && _requestedTab !== 'unit-detail') {
            SPACES.queryFactory.removeQString('detail')
          }

          if (_requestedTab !== 'map' && _requestedTab !== 'plan-detail' && _requestedTab !== 'unit-detail' && _requestedTab !== 'apply') {
            SPACES.utility.log('BINDING SPACES TAB:', _requestedTab)
            SPACES.isoFactory.bindIsotope()
          }

          if (_requestedTab === 'map') {
            SPACES.sightmapFactory = new SPACES.SightMapFactory()
            SPACES.controlFactory.hideNoResults()
          }

          if (_requestedTab !== 'apply' && _requestedTab !== 'plan-detail' && _requestedTab !== 'unit-detail') {
            SPACES.filterFactory.doFilter()
            if (_requestedTab !== 'map') {
              SPACES.sortFactory.doSort()
            }
          }

          if (_requestedTab === 'community') {
            SPACES.queryFactory.removeQString('asset')
            SPACES.filterFactory.doFilter()
          }

          if (_requestedTab === 'community') {
            $('[data-spaces-control="inventory-wrapper"]').addClass('spaces-map-split')

            // Set the view state on the container to hide the community filter when community view is active
            $('[data-spaces-control="inventory-container"]').addClass('spaces-portfolio-community')
            $('[data-spaces-control="inventory-container"]').removeClass('spaces-portfolio-unit')
          } else {
            // In unit view remove all the classes for map state on the inventory wrapper
            $('[data-spaces-control="inventory-wrapper"]').removeClass('spaces-map-split')
            $('[data-spaces-control="inventory-wrapper"]').removeClass('spaces-map-open')
            $('[data-spaces-control="inventory-wrapper"]').removeClass('spaces-map-closed')

            // Set the view state on the container to display the community filter when unit view is active
            $('[data-spaces-control="inventory-container"]').removeClass('spaces-portfolio-community')
            $('[data-spaces-control="inventory-container"]').addClass('spaces-portfolio-unit')
          }

          SPACES.controlFactory.collectControls()
          // Fire the tab switch event to signal controls to rebind
          document.dispatchEvent(SPACES.evTab)

          SPACES.$tabControls.each(function () {
            const $tabControl = $(this)
            $tabControl.find('[data-spaces-control="spinner"]').hide()
          })
        }
      })

      // Update the query string
      SPACES.queryFactory.changeUrl('spaces_tab', _requestedTab)

      $('[data-spaces-controller]').attr('data-spaces-controller', _requestedTab)

      setTimeout(function () {
        $('[data-spaces-control="interstitial"]').removeClass('spaces-active')
      }, 500)

    } else {
      // loading the unit tab in a modal, initialize controls as if the tab had been switched.
      SPACES.controlFactory.collectControls()
      // Fire the tab switch event to signal controls to rebind
      document.dispatchEvent(SPACES.evTab)
    }

    
  }

  return TabFactory
})(jQuery)
