SPACES.AjaxFactory = (function ($) {
  function AjaxFactory () {}

  AjaxFactory.prototype.getData = function (_args) {
    $.post(SPACES.spacesConfig.spaces_ajax_url, _args).done(function (data) {
      return JSON.parse(data)
    })
  }

  AjaxFactory.prototype.getDetail = function (_obj = 'plan', _spaces_asset_id = SPACES.spacesConfig.spaces_asset_id) {
    const args = {
      action: 'spaces_get_detail',
      requested_object: _obj,
      requested_object_id: SPACES.currentDetailId,
      requested_asset_id: _spaces_asset_id
    }

    $.post(SPACES.spacesConfig.spaces_ajax_url, args).done(function (data) {
      SPACES.tabFactory.initTab(data, `${_obj}-detail`)
    })
  }

  AjaxFactory.prototype.getApply = function () {
    const args = {
      action: 'spaces_get_apply',
      requested_asset_id: SPACES.spacesConfig.spaces_asset_id
    }

    $.post(SPACES.spacesConfig.spaces_ajax_url, args).done(function (data) {
      SPACES.tabFactory.initTab(data, 'apply')
    })
  }

  AjaxFactory.prototype.getSightMap = function (_opts = { from: 'unit' }) {
    const args = {
      action: 'spaces_switch_tab',
      requested_tab: 'map',
      requested_asset_id: SPACES.spacesConfig.spaces_asset_id
    }

    SPACES.utility.log(`Pulling SightMap control from server from ${_opts.from} ajax.js:41`)

    $.post(SPACES.spacesConfig.spaces_ajax_url, args).done(function (data) {
      SPACES.controlFactory.openSightMapFromAjax({ data: JSON.parse(data), from: _opts.from })
    })
  }

  AjaxFactory.prototype.getLeaseTermsForUnit = function () {
    const args = {
      action: 'spaces_get_lease_terms_for_unit',
      requested_asset_id: SPACES.spacesConfig.engrain_asset_id,
      requested_unit_id: SPACES.currentLeaseRequest.unitId,
      requested_date: SPACES.currentLeaseRequest.date
    }

    $.post(SPACES.spacesConfig.spaces_ajax_url, args).done(function (data) {
      SPACES.utility.log('Lease terms received:', data)
      SPACES.currentLeaseRequest.terms = data
      SPACES.controlFactory.buildLeaseTermOptions()
      SPACES.controlFactory.updateLeaseTermSelector()
      SPACES.$controls.leaseTermSelector.trigger('change')
    })
  }

  return AjaxFactory
})(jQuery)
