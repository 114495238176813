/* global location history */

SPACES.QueryFactory = (function () {
  function QueryFactory () {}

  const objQueryString = {}

  QueryFactory.prototype.getParameterByName = function (name) {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
    const results = regex.exec(location.search)
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
  }

  QueryFactory.prototype.changeUrl = function (key, value) {
    // Get query string value
    const factory = this
    const searchUrl = location.search.replace(/%2F/g, '/')
    let urlValue = '?' + key + '=' + value
    if (searchUrl.indexOf('?') === -1) {
      urlValue = '?' + key + '=' + value
      history.pushState({
        state: 1,
        rand: Math.random()
      }, '', urlValue)
    } else {
      // Check for key in query string, if not present
      if (searchUrl.indexOf(key) === -1) {
        urlValue = searchUrl + '&' + key + '=' + value
      } else { // If key present in query string
        const oldValue = factory.getParameterByName(key)
        if (searchUrl.indexOf('?' + key + '=') !== -1) {
          urlValue = searchUrl.replace('?' + key + '=' + oldValue, '?' + key + '=' + value)
        } else {
          urlValue = searchUrl.replace('&' + key + '=' + oldValue, '&' + key + '=' + value)
        }
      }
      history.pushState({
        state: 1,
        rand: Math.random()
      }, '', urlValue)
    }
    objQueryString.key = value
    factory.updateSPACESQuery()
  }

  QueryFactory.prototype.removeQString = function (key) {
    let urlValue = document.location.href
    // Get query string value
    const searchUrl = location.search

    const factory = this

    // FIXME: Use switch here
    if (key !== '') {
      const oldValue = factory.getParameterByName(key)
      const removeVal = key + '=' + oldValue
      if (searchUrl.indexOf('?' + removeVal + '&') !== -1) {
        urlValue = urlValue.replace('?' + removeVal + '&', '?')
      } else if (searchUrl.indexOf('&' + removeVal + '&') !== -1) {
        urlValue = urlValue.replace('&' + removeVal + '&', '&')
      } else if (searchUrl.indexOf('?' + removeVal) !== -1) {
        urlValue = urlValue.replace('?' + removeVal, '')
      } else if (searchUrl.indexOf('&' + removeVal) !== -1) {
        urlValue = urlValue.replace('&' + removeVal, '')
      }
    } else {
      urlValue = urlValue.replace(searchUrl, '')
    }
    history.pushState(
      {
        state: 1,
        rand: Math.random()
      },
      '',
      urlValue
    )
    factory.updateSPACESQuery()
  }

  QueryFactory.prototype.queryStringToJSON = function () {
    const pairs = location.search.slice(1).split('&')

    const result = {}
    pairs.forEach(function (pair) {
      pair = pair.split('=')
      result[pair[0]] = decodeURIComponent(pair[1] || '')
    })

    return JSON.parse(JSON.stringify(result))
  }

  QueryFactory.prototype.updateSPACESQuery = function () {
    SPACES.spacesQuery = SPACES.queryFactory.queryStringToJSON()
  }

  return QueryFactory
})()
